import React from 'react'

export default function Footer() {
  return (
    <footer>
     <a href="https://github.com/Jonathanh7"><i class="fab fa-github fa-4x"></i></a>
</footer>
  )
}

